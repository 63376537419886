import { Routes, Route, Link } from "react-router-dom";

import CookieConsent from "react-cookie-consent";

import logo from './logo.svg';
import './App.css';

import {
  About,
  Demo,
  Features,
  Home,
  Pricing,
  Terms,
} from './pages/landing';


function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/features" element={<Features />} />
      <Route path="/privacy" element={<Terms />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/terms" element={<Terms />} />

    </Routes>

    <CookieConsent
       location="bottom"
       buttonText="Accept"
       cookieName="ags-cc-certifly"
       style={{ background: "rgb(32 40 64)" }}
       buttonStyle={{ background: "#335eea", color: "#ffffff", fontSize: "14px", borderRadius: '8px' }}
       expires={7}>
       <b>We use required cookies to track visits and conversions internally.{" "}</b>
       <span style={{ fontSize: "12px" }}>For the record, we don't store or sell any of your data unless you complete our interest form or have a login.</span>
     </CookieConsent>
    </>
  );
}

export default App;
