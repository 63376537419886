import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { BoltIcon, ChatBubbleBottomCenterTextIcon, GlobeAltIcon, ScaleIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import certifly from '../../certifly';
import logo from '../../logo.svg';
import illustration from '../../illustration.svg';

import { Header, Footer } from './'

export default function About() {
  return (
    <div className="relative overflow-hidden">

      {/* Header */}
      <Header />

      {/* Hero */}
      <main>
        <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 xl:py-24">
          <div className="mx-auto max-w-max lg:max-w-7xl">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="max-w-prose text-base lg:max-w-none">
                <h2 className="font-semibold leading-6 text-indigo-600">About {certifly}</h2>
                <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  Our Mission
                </p>
              </div>
            </div>
            <div className="relative">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 hidden md:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
              </svg>
              <svg
                className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
              </svg>
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-1 lg:gap-6">
                    <p>
                      We are a passionate team of IT, Security, Legal and Business leaders who have years of experience across retail, telecommunications, tech and more. We have seen companies large and small both succeed and fail and took our learnings
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />

    </div>
  )
}
