import React, { useState, useEffect } from 'react';

import certifly from '../../certifly';
import logo from '../../logo.svg';
import illustration from '../../illustration.svg';

import { Header, Footer } from './'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/pro-duotone-svg-icons';
library.add(faBook);


export default function Pricing() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  return (
    <div className="relative bg-white">
      <Header />

      <main >
        <div className="bg-white">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                  Pricing with Security Included
                </h2>
                <p className="mt-4 text-xl text-gray-600 pb-8">
                  Our plans come with a 7-day free trial, and always include the default enterprise security features like automated user provisioning and SSO (OAuth and SAML).
                </p>
              </div>
            </div>
          </div>
          <stripe-pricing-table
          className="pt-12"
            pricing-table-id="prctbl_1LesXXAlc5GgtxSUmI6Nt0ZJ"
            publishable-key="pk_test_51LeOmpAlc5GgtxSU2No7jso5UXgytAOoPjpK3o5hTR3kgtDmxSqu7xmUVtGYSvNkRT5rIYqCw1T2Q7Zvf0GFGQuy00NPkXcXkv" />
          <div className="bg-white pb-36"></div>
        </div>
      </main>

      <Footer />
    </div>
  )
}
