import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { BoltIcon, ChatBubbleBottomCenterTextIcon, GlobeAltIcon, ScaleIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import certifly from '../../certifly';
import logo from '../../logo.svg';
import illustration from '../../illustration.svg';

import { Header, Footer } from './'

const features = [
  {
    name: 'Configurable and Extendable',
    description:
      'If you are a newer company, start with our wizard where we have boilerplate templates for you to start using. If you are established, upload your policy so you have a central repository to track engagement and reference it in the future.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Meet Compliance Requirements',
    description:
      'Our platform can attest when an employee viewed, and confirmed receipt of the corporate policy. This is often required for different government or regulatory body requirements.',
    icon: ScaleIcon,
  },
  {
    name: 'Reporting Built In',
    description:
      'No more spreadsheets to track who received the email you sent, and no more difficult LMS integrations. Our platform will track compliance for you.',
    icon: BoltIcon,
  },
  {
    name: 'Increase Employee Engagement and Adherence',
    description:
      'We integrate with Slack to send reminders to your employees about your companies\' policies and let them know when things change, or give them a quick refresher from time to time',
    icon: ChatBubbleBottomCenterTextIcon,
  },
]

export default function Home() {
  return (
    <div className="relative overflow-hidden">

      {/* Header */}
      <Header />

      {/* Hero */}
      <main>
        <div className="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                <div className="lg:py-24">
                  <a
                    href="/pricing"
                    className="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                  >
                    <span className="rounded-full bg-indigo-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                      In Beta
                    </span>
                    <span className="ml-4 text-sm">Get discount pricing before we launch!</span>
                    <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                  </a>
                  <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">A new way to manage </span>
                    <span className="block text-indigo-400">corporate policy compliance</span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Finally, a service that your company can afford, to manage your corporate policies, employee compliance and more, that your employees will actually want to use.
                  </p>
                  <div className="mt-10 sm:mt-12">
                    <form action="#" className="sm:mx-auto sm:max-w-xl lg:mx-0">
                      <div className="sm:flex">
                        <div className="min-w-0 flex-1">
                          <label htmlFor="email" className="sr-only">
                            Email address
                          </label>
                          <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900"
                          />
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                          <button
                            type="submit"
                            className="block w-full rounded-md bg-indigo-500 py-3 px-4 font-medium text-white shadow hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900"
                          >
                            Start free trial
                          </button>
                        </div>
                      </div>
                      <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                        Get a demo of Certifly, all we need is your email and for you to agree to
                        our{' '}
                        <a href="/terms" className="font-medium text-white">
                          terms of service
                        </a>
                        .
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={illustration}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>

      {/* Sell */}
      <div className="bg-white py-12">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-lg font-semibold text-indigo-600">Cybersecurity Policy Compliance</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Your team already works hard, make compliance easy
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              {certifly} is designed to be a cheap value-add for your growing company. When you compromise compliance for convenience, you put your users and employees at risk. We literally want to make the world a better place and it starts one internet-enabled company at a time.
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
