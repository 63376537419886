import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { BoltIcon, ChatBubbleBottomCenterTextIcon, GlobeAltIcon, ScaleIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import certifly from '../../certifly';
import logo from '../../logo.svg';
import illustration from '../../illustration.svg';

import { Header, Footer } from './'
export default function Terms() {
  return (
    <div className="relative overflow-hidden">

      {/* Header */}
      <Header />

      {/* Hero */}
      <main>
        <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 xl:py-24">
          <div className="mx-auto max-w-max lg:max-w-7xl">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="max-w-prose text-base lg:max-w-none">
                <h2 className="font-semibold leading-6 text-indigo-600">Terms of Service</h2>
                <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  Acceptable Use of Our Site and Services
                </p>
                <h3 className="font-semibold text-indigo-300">Revision: September 2022</h3>
              </div>
            </div>
            <div className="relative">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 hidden md:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
              </svg>
              <svg
                className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
              </svg>
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                  <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                    <p>
                      By visiting and/or taking any action with {certifly}, you confirm that you are in agreement with and bound by the terms outlined below. These terms apply to the website, application (web, mobile or other), emails, or any other communication.
                    </p>

                    <p className="mt-2 text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-xl">
                      Payments
                    </p>
                    <p>
                      All payments are processed securely through Stripe. {certifly} does not directly process payments through the website. None of your payment data is stored on our servers.
                    </p>
                    <p>
                      Failure to pay your invoice on time whether through subscription or purchase order, will result in termination of your account. You have 7 days to reconcile your account before your data is purged from the platform.
                    </p>

                    <p className="mt-2 text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-xl">
                      Access
                    </p>
                    <p>
                      While we do implement standards such as OAuth and SAML, direct system access using a 'local' account is supported. Administrators may disable this login method. We do not support username/passwords. Passwordless logins require the users email address in order to access the platform. We are not responsible for mis-directed emails and we are not responsible for the accuracy of the data you enter into our platform. In the event a users' email inbox is compromised, an malicious actor may gain access to our platform. We are not responsible for the security of your users' inboxes.
                    </p>
                    <p>
                      Use is a benefit for those who follow our terms and policies. We may at any time suspend or terminate your account.
                    </p>
                  </div>
                  <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                    <p className="mt-2 text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-xl">
                      Data
                    </p>
                    <p>
                      You may request at any time to purge your data from the platform, however a refund will not be given.
                    </p>
                    <p>
                      Our templated and boilerplate policies are property of {certifly} and AngryStatic, Inc. You may continue to use them after your engagement with us ceases for a nominal licensing fee. Unlicensed use is strictly prohibited.
                    </p>

                    <p className="mt-2 text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-xl">
                      Keeping it Simple
                    </p>
                    <p>
                      We have the right to change our policies whenever we deem it neccessary. We will notify users when our policy changes, however it is up to the user to agree to the changes or end their engagement with {certifly}.
                    </p>
                    <p>
                      We try to keep our policies has human-readable as possible, and hope this extra transparency brings you comfort in using our services. We will always try to do the right thing, we will never take shortcuts, and we will always try to be better.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 xl:py-24">
          <div className="mx-auto max-w-max lg:max-w-7xl">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="max-w-prose text-base lg:max-w-none">
                <h2 className="font-semibold leading-6 text-indigo-600">Privacy Policy</h2>
                <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  Keeping Your Data Safe
                </p>
                <h3 className="font-semibold text-indigo-300">Revision: September 2022</h3>
              </div>
            </div>
            <div className="relative">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 hidden md:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
              </svg>
              <svg
                className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
              </svg>
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-1 lg:gap-6">
                  <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                    <p>
                      As a service provider within the field of information and cyber security, it is important that we aim to be leaders in the field by always doing what is right.
                    </p>
                    <p>
                      To start, we do not sell your data, ever. We use Google Analytics as our analytics platform for tracking who has accessed our web page, who has converted from a viewer to a customer, and the performance of our application so we can make the experience better for you. We only ask for one field for non-customers, which is the email address field, so we can know how to get in touch with you. We know emails are sensitive in some scenarios depending on where you are accessing our site from. We take extra precautions to secure this piece of information.
                    </p>
                    <p>
                      If you become a paying subscriber, we need more information to provide you service. We use Stripe as our payment processor and do not store any of your data locally. That information is:
                    </p>
                    <ol role="list">
                      <li>A list of your employees names, email addresses, title and related field's that you configure</li>
                      <li>Which of your users are considered administrators</li>
                      <li>Any custom policy you upload</li>
                      <li>Required information for 3rd-party opt-in integrations such as Slack, Google and Okta</li>
                    </ol>
                    <p>
                      This data is secured using industry best-practices and is monitored by our staff. As we continue to grow, we will seek out SOC2 compliance, while we are not quite there yet funding wise, we do hold ourselves to the same standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />

    </div>
  )
}
