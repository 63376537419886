import React, { useState, useEffect } from 'react';

import certifly from '../../certifly';
import logo from '../../logo.svg';

import { Header, Footer } from './'

export default function Demo() {

  return (
    <div className="relative bg-white">
      <Header />

      <main >
        <div className="bg-white">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                  Our Demo is Coming Soon!
                </h2>
                <p className="mt-4 text-xl text-gray-600 pb-8">
                  Thanks for your excitement! We are working on our demo and building out some features like free-trials and more! Check back soon.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}
